
import Vue from 'vue';

import { PackSchema } from '@/schemas/Pack.schema';
import '../interfaces/Pack.interface';

export default Vue.extend({
	computed: {
		defaultPack() {
			return PackSchema;
		}
	},
	data: () => ({
		currentStep: 0,
		pack: {}
	}),
	mounted() {
		this.pack = Object.assign({}, this.defaultPack);
	}
});
